<template>
  <el-dialog
    :visible="visible"
    width="468px"
    title="提示"
    @close="handleClose"
  >
    <div class="content-wrapper">
      用户被移出房间后，本场直播过程中不可再次进入
    </div>
    <div
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        size="mini"
        @click="handleClose"
      >
        取消
      </el-button>
      <el-button
        type="primary"
        size="mini"
        @click="handleKickOut"
      >
        确认移出
      </el-button>
    </div>
  </el-dialog>
</template>
<script>
import { setKickOut } from '@/services/room';

export default {
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    kickOutDialog: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      roomId: this.$route.params.id,
    };
  },
  methods: {
    async handleKickOut() {
      const { uid, name } = this.kickOutDialog;
      try {
        const res = await setKickOut({ // 设置用户为观众
          roomId: this.roomId,
          uid,
        });
        if (res && !Object.keys(res).length) {
          this.$message.success(`${name}已被移出房间`);
        }
      } catch (error) {
        // this.$message.error(error.msg);
      } finally {
        this.handleClose();
      }
    },
    handleClose() {
      this.$emit('update:visible', false);
    },
  },
};
</script>
<style lang="scss" scoped>
.content-wrapper {
  padding: 22px 0;
  line-height: 20px;
}
</style>
